import styled from "styled-components"

const Wrapper = styled.header`
  height: 80px;
  max-width: 1150px;
  margin: auto;
  display: flex;
  justify-content: space-between;
  padding: 0px 25px;
  align-items: center;
  position: relative;
`

export default Wrapper
