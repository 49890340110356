import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"

import { ThemeProvider } from "styled-components"
import GlobalStyle from "../../utils/styled-components/globalStyles"
import { lightTheme, darkTheme } from "../../utils/styled-components/theme"

import karelKvitekSocial from "../../images/karel-kvitek-social.png"

//React components imports
import Header from "../Header"
import Footer from "../Footer"
import Wrapper from "./Wrapper"
import Container from "./Container"
import Main from "./Main"

const Layout = ({ children, location }) => {
  const themeContext = useContext(ThemeManagerContext)
  return (
    <ThemeProvider theme={themeContext.isDark ? darkTheme : lightTheme}>
      <GlobalStyle />
      <Wrapper>
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <title>Karel Kvítek - webový vývojář</title>
          <meta
            name="description"
            content="Jsem freelancer specializující se na vývoj moderních webových stránek, aplikací a všeho mezi tím. Jsem tu pro zákazníky, kteří očekávají flexibilitu, spolehlivost a profesionalitu."
          />
          <meta property="og:locale" content="cs_CZ" />
          <meta property="og:type" content="person" />
          <meta property="og:title" content="Karel Kvítek" />
          <meta
            property="og:image"
            content={`https://karelkvitek.cz${karelKvitekSocial}`}
          />
          <meta property="og:image:width" content="630" />
          <meta property="og:image:height" content="335" />
          <meta
            property="og:description"
            content="Jsem freelancer specializující se na vývoj moderních webových stránek, aplikací a všeho mezi tím. Jsem tu pro zákazníky, kteří očekávají flexibilitu, spolehlivost a profesionalitu."
          />
          <meta property="og:url" content="https://karelkvitek.cz" />
        </Helmet>
        <Container>
          <Header location={location} />
          <Main>{children}</Main>
        </Container>
        <Footer />
      </Wrapper>
    </ThemeProvider>
  )
}

export default Layout
