import styled from "styled-components"

const Text = styled.p`
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  margin-top: ${props => (props.marginTop ? props.marginTop : "0px")};

  @media (${props => props.theme.mediaQueries.tablet}) {
    text-align: left;
  }
`

export default Text
