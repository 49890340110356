import styled, { css } from "styled-components"
import { Link as GatsbyLink } from "gatsby"

const Link = styled(GatsbyLink)`
  font-size: 18px;
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  transition: color 0.2s ease-in-out;

  ${props =>
    !props.active &&
    css`
      &:hover {
        color: ${props => props.theme.colors.main};
      }
    `}
`

export default Link
