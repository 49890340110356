import styled from "styled-components"

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  transition: background-color 0.1s ease-in-out;
  background: ${props => props.theme.colors.background};
`

export default Wrapper
