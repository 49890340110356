import styled from "styled-components"

const IconsWrapper = styled.div`
  text-align: center;

  @media (${props => props.theme.mediaQueries.tablet}) {
    order: 1;
  }
`

export default IconsWrapper
