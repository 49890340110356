import styled from "styled-components"
import { Github } from "@styled-icons/boxicons-logos/Github"

const GithubIcon = styled(Github)`
  width: 24px;
  height: 24px;
  margin-left: 20px;
  color: ${props => props.theme.colors.text};

  &:hover {
    color: #bdccdb;
  }
`

export default GithubIcon
