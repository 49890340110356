import styled from "styled-components"
import { FacebookCircle } from "@styled-icons/boxicons-logos/FacebookCircle"

const FacebookIcon = styled(FacebookCircle)`
  width: 24px;
  height: 24px;
  color: ${props => props.theme.colors.text};

  &:hover {
    color: #4267b2;
  }
`

export default FacebookIcon
