import styled from "styled-components"

const Container = styled.div`
  max-width: 1150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 20px 25px 15px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    padding: 0 25px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
`

export default Container
