import React, { useState, useContext } from "react"
import { ThemeManagerContext } from "gatsby-styled-components-dark-mode"

//Styled components imports
import Wrapper from "./Wrapper"
import LogoWrapper from "./LogoWrapper"
import NavLinks from "./NavLinks"
import NavLink from "./NavLink"
import Link from "./Link"
import Nav from "./Nav"
import MoonIcon from "./MoonIcon"
import SunIcon from "./SunIcon"
import MoonIconWrapper from "./MoonIconWrapper"
import SunIconWrapper from "./SunIconWrapper"

//React component import
import Logo from "./Logo"

// //SVG logo import
// import Logo from "../../../images/logo.svg"

const Navbar = ({ location }) => {
  const [logoHover, setLogoHover] = useState(false)
  const [isInitial, setIsInitial] = useState(true)
  const themeContext = useContext(ThemeManagerContext)

  return (
    <Wrapper>
      <LogoWrapper
        to="/"
        onMouseEnter={() => setLogoHover(true)}
        onMouseLeave={() => setLogoHover(false)}
      >
        <Logo
          onMouseEnter={() => setLogoHover(true)}
          onMouseLeave={() => setLogoHover(false)}
          hover={logoHover}
        />
      </LogoWrapper>
      <Nav>
        <NavLinks>
          {location === "portfolio" ? (
            <NavLink active="true">
              <Link active="true" to="/">
                portfolio
              </Link>
            </NavLink>
          ) : (
            <NavLink>
              <Link to="/">portfolio</Link>
            </NavLink>
          )}

          {location === "about" ? (
            <NavLink active="true">
              <Link active="true" to="/about">
                o mně
              </Link>
            </NavLink>
          ) : (
            <NavLink>
              <Link to="/about">o mně</Link>
            </NavLink>
          )}

          {location === "contact" ? (
            <NavLink active="true">
              <Link active="true" to="/contact">
                kontakt
              </Link>
            </NavLink>
          ) : (
            <NavLink>
              <Link to="/contact">kontakt</Link>
            </NavLink>
          )}
        </NavLinks>
        {themeContext.isDark ? (
          <SunIconWrapper
            initial={isInitial ? false : { scale: 0.1, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
            onClick={() => {
              themeContext.toggleDark(false)
            }}
          >
            <SunIcon
              onClick={() => {
                setIsInitial(false)
              }}
            />
          </SunIconWrapper>
        ) : (
          <MoonIconWrapper
            initial={isInitial ? false : { scale: 0.1, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
            onClick={() => {
              themeContext.toggleDark(true)
            }}
          >
            <MoonIcon
              onClick={() => {
                setIsInitial(false)
              }}
            />
          </MoonIconWrapper>
        )}
      </Nav>
    </Wrapper>
  )
}

export default Navbar
