import React from "react"

//React components imports
import Navbar from "./Navbar"

//Styled components imports
import Wrapper from "./Wrapper"

const Header = ({ location }) => {
  return (
    <Wrapper>
      <Navbar location={location} />
    </Wrapper>
  )
}

export default Header
