import styled from "styled-components"
import { motion } from "framer-motion"

const MoonIconWrapper = styled(motion.div)`
  position: absolute;
  right: 25px;
  bottom: -45px;

  @media (min-width: 420px) {
    position: static;
    right: 0;
    bottom: 0;
  }
`

export default MoonIconWrapper
