import styled, { css } from "styled-components"

const NavLink = styled.li`
  margin-left: 12px;
  display: block;
  height: 100%;
  padding: 27px 0;

  @media (min-width: 330px) {
    margin-left: 16px;
  }

  @media (min-width: 345px) {
    margin-left: 20px;
  }

  @media (min-width: 365px) {
    margin-left: 30px;
  }

  ${props =>
    props.active &&
    css`
      border-bottom: 2px solid ${props => props.theme.colors.main};
    `}
`

export default NavLink
