import React from "react"

//Styled components imports
import Wrapper from "./Wrapper"
import Container from "./Container"
import IconsWrapper from "./IconsWrapper"
import GithubIcon from "./GithubIcon"
import FacebookIcon from "./FacebookIcon"
import TextsWrapper from "./TextsWrapper"
import Text from "./Text"
import Media from "./Media"

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <IconsWrapper>
          <a
            href="https://www.facebook.com/kvitek.karel"
            aria-label="Facebook"
            title="Facebook - Karel Kvítek"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://github.com/Karlos137"
            aria-label="GitHub"
            title="GitHub - Karel Kvítek"
            target="_blank"
            rel="noreferrer"
          >
            <GithubIcon />
          </a>
        </IconsWrapper>
        <TextsWrapper>
          <Media lessThan="tablet">
            <Text marginTop="20px">
              Stránky navrhl a vytvořil Karel Kvítek.
            </Text>
          </Media>
          <Media greaterThanOrEqual="tablet">
            <Text>Stránky navrhl a vytvořil Karel Kvítek.</Text>
          </Media>
          <Text marginTop="6px">&copy; {new Date().getFullYear()}</Text>
        </TextsWrapper>
      </Container>
    </Wrapper>
  )
}

export default Footer
