import styled from "styled-components"

const Footer = styled.footer`
  background: ${props => props.theme.colors.background2};
  height: 135px;
  margin-top: 42px;
  transition: background-color 0.1s ease-in-out;

  @media (${props => props.theme.mediaQueries.tablet}) {
    height: 80px;
    margin-top: 64px;
  }
`

export default Footer
