import { breakpoints } from "../Media"

export const darkTheme = {
  colors: {
    main: "#F80B77",
    hover: "#90e0ef",
    complementary: "#ccd6f6",
    text: "#FAFAFA",
    background: "#0E141B",
    background2: "#17202B",
  },
  mediaQueries: {
    mobile: `min-width: ${breakpoints.mobile}px`,
    tablet: `min-width: ${breakpoints.tablet}px`,
    desktop: `min-width: ${breakpoints.desktop}px`,
    largeDesktop: `min-width: ${breakpoints.largeDesktop}px`,
  },
}

export const lightTheme = {
  colors: {
    main: "#F80B77",
    hover: "#1CABC4",
    complementary: "#2A3C50",
    text: "#0E141B",
    background: "#f8f9fa",
    background2: "#e9ecef",
  },
  mediaQueries: {
    mobile: `min-width: ${breakpoints.mobile}px`,
    tablet: `min-width: ${breakpoints.tablet}px`,
    desktop: `min-width: ${breakpoints.desktop}px`,
    largeDesktop: `min-width: ${breakpoints.largeDesktop}px`,
  },
}
