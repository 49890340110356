import React from "react"

//Styled components imports
import TagPath from "./TagPath"
import KPath from "./KPath"

const Logo = ({ hover }) => {
  return (
    <svg
      width="42"
      height="25"
      viewBox="0 0 42 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Logo - Karel Kvítek</title>
      <g clipPath="url(#clip0)">
        <TagPath
          d="M14.5256 23.5716L24.854 -0.0419922H27.7277L17.3994 23.5716H14.5256Z"
          fill="#F80B77"
          hover={hover}
        />
      </g>
      <TagPath
        d="M6.78558 11.568L14.2242 21.1965H10.5333L3.31235 11.568L10.2493 1.7666H13.7983L6.78558 11.568Z"
        fill="#F80B77"
        hover={hover}
      />
      <KPath
        d="M0 1.7666H2.92433V11.4815V21.1965H0V11.4815V1.7666Z"
        fill="#FAFAFA"
        hover={hover}
      />
      <TagPath
        d="M35.2144 11.9713L27.7758 21.5998H31.4667L38.6877 11.9713L31.7507 2.16992H28.2017L35.2144 11.9713Z"
        fill="#F80B77"
        hover={hover}
      />
      <KPath
        d="M42 2.16992H39.0757V11.8849V21.5998H42V11.8849V2.16992Z"
        fill="#FAFAFA"
        hover={hover}
      />
      <defs>
        <clipPath id="clip0">
          <rect
            width="14.2264"
            height="24.8962"
            fill="white"
            transform="translate(13.8879)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Logo
