import styled from "styled-components"
import { Moon } from "@styled-icons/boxicons-solid/Moon"

const MoonIcon = styled(Moon)`
  width: 26px;
  height: 26px;
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  margin-left: 30px;
  margin-bottom: 4px;
`

export default MoonIcon
